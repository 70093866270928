<template>
  <component :is="productData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="productData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching product data
      </h4>
      <div class="alert-body">
        No product found with this product id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-products-list'}"
        >
          Product List
        </b-link>
        for other products.
      </div>
    </b-alert>

    <b-tabs
      v-if="productData"
      pills
    >

      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="ProductIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Manage Product</span>
        </template>
        <product-edit-tab-account
          :product-data="productData"
          class="mt-2 pt-75"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import productsStoreModule from '../productsStoreModule'
import ProductEditTabAccount from './ProductEditTabAccount.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    ProductEditTabAccount,
  },
  setup() {
    const productData = ref(null)

    const PRODUCTS_APP_STORE_MODULE_NAME = 'products'

    // Register module
    if (!store.hasModule(PRODUCTS_APP_STORE_MODULE_NAME)) store.registerModule(PRODUCTS_APP_STORE_MODULE_NAME, productsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PRODUCTS_APP_STORE_MODULE_NAME)) store.unregisterModule(PRODUCTS_APP_STORE_MODULE_NAME)
    })

    store.dispatch('products/fetchProduct', { id: router.currentRoute.params.id })
      .then(response => { productData.value = response.data })
      .catch(error => {
        if (error.response.data.status === 404) {
          productData.value = undefined
        }
      })

    return {
      productData,
    }
  },
}
</script>

<style>

</style>
