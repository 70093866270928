<template>
  <div>
    <!-- Product Info: Input Fields -->
    <b-form>
      <h5 class="title">Statistics</h5>
      <b-row>
        <!-- Field: Full Name -->
        <b-col
          cols="12"
          md="4"
        >
        <p> - Total Inquiries
          <b-badge
          variant="primary"
          pill
          >
          {{ productData.num_inquiries }}
          </b-badge>
        </p>
        </b-col>
      </b-row>
      <hr/>
      <h5 class="title">Product Info</h5>
      <b-row>
        <!-- Field: Full Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Name"
            label-for="name"
          >
            <b-form-input
              id="full-name"
              v-model="productData.name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Product Info Summary"
            label-for="description"
          >
          <b-form-textarea
                id="description"
                v-model="productData.description"
                autofocus
                rows="3"
                max-rows="3"
                trim
              />
          </b-form-group>
        </b-col>
        <!-- Field: Email -->
      </b-row>
      <h5 class="title">USSD Display Settings</h5>
      <hr/>
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Is Offered"
            label-for="priority"
          >
          <b-form-checkbox
                id="is_active"
                v-model="productData.is_active"
                name="is-active-button"
                switch
              />
              <small>determines if product can be shown on ussd menu</small>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Show Product Info Summary in USSD Menu"
            label-for="priority"
          >
          <b-form-checkbox
                id="show_info"
                v-model="productData.show_info"
                name="show-info-button"
                switch
              />
              <small>switch on to add extra screen for product description in USSD menu</small>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="USSD Display Priority"
            label-for="priority"
          >
          <b-form-input
                id="priority"
                type="number"
                v-model="productData.priority"
                autofocus
              />
              <small>Product with higher priority is displayed first on USSD menu</small>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <!-- Action Buttons -->
    <b-button
      variant="primary"
      type="button"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      @click="onSubmit"
    >
      Save Changes
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Reset
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BFormTextarea, BFormCheckbox, BBadge,
} from 'bootstrap-vue'
import { useToast } from 'vue-toastification/composition'
import { ref, toRefs } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useProductsList from '../products-list/useProductsList'
import router from '@/router'
import store from '@/store'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormTextarea,
    BFormCheckbox,
    BBadge,
  },
  props: {
    productData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { resolveProductRoleVariant } = useProductsList()

    const roleOptions = [
      { label: 'Super Admin', value: 1 },
      { label: 'Organisation Manager', value: 2 },
    ]

    const statusOptions = [
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
    ]

    const permissionsData = [
      {
        module: 'Payments',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff Accounts',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const toast = useToast()
    const productDataRef = toRefs(props).productData
    const onSubmit = () => {
      const productEditData = {
        id: productDataRef.value.id,
        name: productDataRef.value.name,
        priority: productDataRef.value.priority,
        is_active: productDataRef.value.is_active,
        show_info: productDataRef.value.show_info,
        description: productDataRef.value.description,
      }
      store.dispatch('products/editProduct', productEditData)
        .then(() => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Changes Saved',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Changes have been changed Successfully',
            },
          })
        })
      router.push({ name: 'apps-products-list' })
    }
    return {
      resolveProductRoleVariant,
      roleOptions,
      statusOptions,
      permissionsData,
      onSubmit,
      productDataRef,
      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
