import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProducts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/products', { params: queryParams })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchProduct(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/products/${id}/`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addProduct(ctx, productData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/products/', productData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editProduct(ctx, productData) {
      const { id } = productData
      return new Promise((resolve, reject) => {
        axios
          .patch(`/products/${id}/`, productData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
